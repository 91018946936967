import React, { useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Seo from "../components/seo";

import Arrow from "../images/arrow2.png";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ActionCards from "../components/ActionCards/ActionCards";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import { Btn, BtnContainer, BtnImg, TextButton } from "../components/constants";
import MapBox from "../components/MapBox";
import { ContainerFluid, ContainerL } from "../ui/containers";
import FormBlock from "../components/Form";
import Modal from "../ui/modal";
import "../components/layout.css";

const HeadingText = styled.div`
  font-weight: 200;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 600px) {
    font-weight: 200;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`;

const ActionBlock = styled.div`
  margin: 90px 0 0 ${props => props.marginLeft}px;
`;

const Contacts = ({ data }) => {
  const screens = useBreakpoint();
  const [openModal, setOpenModal] = useState(false);
  const contactsData = data.allStrapiContacts.nodes[0];

  const toggleOpenModal = () => {
    if (!openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setOpenModal(!openModal);
  };
  return (
    <>
      <Seo title={"Контакты"} />
      <Header />
      {
        openModal ?
          (<Modal toggleOpenModal={toggleOpenModal} />)
          :
          (
            <>
              <ContainerL style={{ margin: "120px auto" }}>
                <HeadingText>
                  КОНТАКТЫ
                </HeadingText>

                <ActionBlock marginLeft={!screens.xl ? 30 : 330}>
                  <ActionCards contacts={contactsData} />
                  <BtnContainer>
                    <Btn shape="circle" type="primary" size={"large"} onClick={toggleOpenModal}>
                      <BtnImg src={Arrow} />
                    </Btn>
                    <TextButton>
                      ПЕРЕЗВОНИТЕ МНЕ
                    </TextButton>
                  </BtnContainer>
                </ActionBlock>
              </ContainerL>

              <ContainerFluid style={{ margin: "120px auto" }}>
                <MapBox />
              </ContainerFluid>

              <ContainerL style={{ margin: "120px auto" }}>
                <FormBlock />
              </ContainerL>

              <Footer toggleOpenModal={toggleOpenModal} />
            </>
          )}

    </>
  );
};

export default Contacts;

export const query = graphql`
    query ContactsQuery {
        allStrapiContacts {
            nodes {
                email
                adress
                phone
                schedule
            }
        }
    }

`;
